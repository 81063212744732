import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import BrandComponent from "../../authentication/BrandComponent";
import { loginUser } from "../../../store/user/UserActions";

const AdminLogin = () => {
  const { is_staff } = useSelector((state) => state.user?.user ?? false);
  const [formValidated, setFormValidated] = useState(false);

  const dispatch = useDispatch();

  const { loading, isAuthenticated, error } = useSelector(
    (state) => state.user
  );

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  async function handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setFormValidated(true);
    } else {
      setFormValidated(false);
      dispatch(loginUser(formData));
    }
  }

  return (
    <div className="authpage">
      <Container>
        <Row>
          <BrandComponent></BrandComponent>
          <Col sm={6} className="authform">
            {error && (
              <Alert variant="danger" autoFocus={true}>
                {error}
              </Alert>
            )}
            {isAuthenticated && is_staff && (
              <Alert variant="danger" autoFocus={true}>
                "Login With Valid Admin Credentials"
              </Alert>
            )}
            <h2>Admin Login</h2>

            <Form
              noValidate
              validated={formValidated}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Form.Group className="mb-3">
                <Form.Label>Email </Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  required
                  maxLength={64}
                  value={email}
                  onChange={(e) => onChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Email Address.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password </Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  required
                  value={password}
                  onChange={(e) => onChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Your Password.
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                className="form-submit-btn"
                variant="primary"
                type="submit"
              >
                {loading ? "Loading..." : "Login"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminLogin;
