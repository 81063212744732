import React from "react";
import { Col } from "react-bootstrap";
import tcfImage from "../../assets/images/tcfImage.png";

const BrandComponent = () => {
  return (
    <Col sm={6} className="brandcomponent">
      <img src={tcfImage} alt="tcf" />
      <h4>Solutions Exceeding Expectations</h4>
    </Col>
  );
};

export default BrandComponent;
