import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import BrandComponent from "../BrandComponent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sign_up } from "../../../store/user/UserActions";
import { validateEmail, validatePhoneNumber } from "../../../CommonMethods";

const SignUp = () => {
  const [formValidated, setFormValidated] = useState(false);

  const { loading, error } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    re_password: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    // address: {
    //   address: "",
    //   town: "",
    //   county: "",
    //   country: "Kenya",
    // },
  });

  const {
    email,
    password,
    re_password,
    first_name,
    last_name,
    phone_number,
    // address,
  } = formData;

  const [isEmailValid, setIsEmailValid] = useState(false);

  const [isPhoneNumberValid, setPhoneNumberValid] = useState(false);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  async function handleSubmit(e) {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setFormValidated(true);
      window.scrollTo(0, 0);
    } else {
      if (password === re_password && isEmailValid && isPhoneNumberValid) {
        setFormValidated(false);
        dispatch(
          sign_up({
            name: `${first_name} ${last_name}`,
            phone_number: phone_number,
            email: email,
            password: password,
            re_password: re_password,
          })
        ).then((response) => {
          window.scrollTo(0, 0);

          if (response.payload) {
            navigate("/", {
              state: { notification: "Verify your Email address" },
            });
          }
        });
      } else {
        window.scrollTo(0, 150);
      }
    }
  }

  useEffect(() => {
    setIsEmailValid(validateEmail(email));
    setPhoneNumberValid(validatePhoneNumber(phone_number));
  }, [email, phone_number]);

  // if (isAuthenticated) {
  //   return <Navigate to={"/"} />;
  // }

  return (
    <div className="authpage">
      <Container>
        <Row>
          <BrandComponent></BrandComponent>
          <Col sm={6} className="authform">
            {error && (
              <Alert variant="danger" autoFocus={true}>
                <Alert.Heading>{error}</Alert.Heading>
              </Alert>
            )}
            <h2>Sign Up</h2>
            <p>
              Already Have An Account? <a href="/login">LogIn</a>{" "}
            </p>
            <Form
              noValidate
              validated={formValidated}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Row className="mb-3">
                <Form.Group as={Col} md="6">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    required
                    maxLength={64}
                    value={first_name}
                    onChange={(e) => onChange(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter First Name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    required
                    maxLength={64}
                    value={last_name}
                    onChange={(e) => onChange(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Last Name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number </Form.Label>
                <Form.Control
                  type="text"
                  name="phone_number"
                  className={
                    isPhoneNumberValid
                      ? "custom-validate-class-valid"
                      : "me-2  custom-validate-class-invalid"
                  }
                  isInvalid={!isPhoneNumberValid}
                  required
                  maxLength={15}
                  value={phone_number}
                  onChange={(e) => onChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter A Valid Phone Number.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email </Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  className={
                    isEmailValid
                      ? "custom-validate-class-valid"
                      : "me-2  custom-validate-class-invalid"
                  }
                  isInvalid={!isEmailValid}
                  required
                  maxLength={64}
                  value={email}
                  onChange={(e) => onChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter a Valid Email Address
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password </Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  minLength={8}
                  required
                  value={password}
                  onChange={(e) => onChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  {password.length < 1
                    ? "Please Enter A Password."
                    : "Pasword Not less that 8 Characters"}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password </Form.Label>
                <Form.Control
                  type="password"
                  name="re_password"
                  isInvalid={password !== re_password}
                  className={
                    password.length > 0
                      ? password === re_password
                        ? "custom-validate-class-valid"
                        : "me-2  custom-validate-class-invalid"
                      : ""
                  }
                  required
                  value={re_password}
                  onChange={(e) => onChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  {re_password.length < 1
                    ? "Please Confirm Password."
                    : "Password Should Match"}
                </Form.Control.Feedback>
              </Form.Group>
              {/* <br />
              <p>Address (Optional)</p>
              <Row className="mb-3">
                <Form.Group as={Col} md="6">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    maxLength={64}
                    value={address.address}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        address: { ...address, address: e.target.value },
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Town</Form.Label>
                  <Form.Control
                    type="text"
                    name="town"
                    maxLength={32}
                    value={address.town}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        address: { ...address, town: e.target.value },
                      })
                    }
                  />
                </Form.Group>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label>County </Form.Label>
                <Form.Control
                  type="text"
                  name="county"
                  maxLength={32}
                  value={address.county}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      address: { ...address, county: e.target.value },
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Country </Form.Label>
                <Form.Control
                  type="text"
                  name="country"
                  maxLength={32}
                  value={address.country}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      address: { ...address, country: e.target.value },
                    })
                  }
                />
              </Form.Group> */}
              <br />
              <Button
                className="form-submit-btn"
                variant="primary"
                type="submit"
              >
                {loading ? "Signing Up...." : "Sign Up"}
              </Button>
            </Form>
            <p>
              <i> Verify Your Email On Signing Up.</i>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUp;
