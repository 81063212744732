import React, { useState } from "react";
import { Button, Col, Dropdown, Form, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../store/user/UserActions";
import { logout } from "../../store/user/UserSlice";

const HoveringLoginRegister = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isAuthenticated, user, error } = useSelector((state) => state.user);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  async function signInUser(e) {
    e.preventDefault();
    dispatch(loginUser(formData));
  }
  return (
    <div className="hovering_login_register">
      <Col sm={6}>
        {/* <Button>LOGIN</Button> */}
        {isAuthenticated ? (
          <Link>
            <Button>{user?.email}</Button>
          </Link>
        ) : (
          <Form onSubmit={(e) => signInUser(e)}>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">LOGIN</Dropdown.Toggle>

              <Dropdown.Menu>
                {error && (
                  <Dropdown.ItemText style={{ color: "red" }}>
                    Validation Error!
                  </Dropdown.ItemText>
                )}
                <Dropdown.ItemText>
                  <FormControl
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={email}
                    required
                    onChange={(e) => onChange(e)}
                  />
                </Dropdown.ItemText>
                <Dropdown.ItemText>
                  <FormControl
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(e) => onChange(e)}
                  />
                </Dropdown.ItemText>

                <Dropdown.ItemText>
                  <Button type="submit">SIGN IN</Button>
                </Dropdown.ItemText>
              </Dropdown.Menu>
            </Dropdown>
          </Form>
        )}
      </Col>
      <Col sm={6}>
        {isAuthenticated ? (
          <Link>
            <Button
              onClick={() => {
                dispatch(logout());
                navigate("/");
              }}
              variant="warning"
              style={{ backgroundColor: "#e53434" }}
            >
              LOGOUT
            </Button>
          </Link>
        ) : (
          <Link to={"/signup"}>
            <Button>REGISTER</Button>{" "}
          </Link>
        )}
      </Col>
    </div>
  );
};

export default HoveringLoginRegister;
