import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";

const HoveringSearchBar = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const [search, setSearch] = useState("");

  function handleSearch(e) {
    e.preventDefault();
    if (location.pathname === "/") {
      navigate(`/catalog/?q=${search}`);
    } else {
      urlParams.set("q", search);
      navigate(`?${urlParams.toString()}`);
    }
  }

  return (
    <div className="hovering_search_bar">
      <Form className="d-flex" onSubmit={(e) => handleSearch(e)}>
        <InputGroup>
          <Form.Control
            type="search"
            placeholder="Search"
            aria-label="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
          <InputGroup.Text>
            <Button variant="outline-success" type="submit">
              <Search size={22} color="black" fontWeight="bold" />
            </Button>
          </InputGroup.Text>
        </InputGroup>
      </Form>
    </div>
  );
};

export default HoveringSearchBar;
