import React from "react";

const ListTile = ({ id, title, onChange, checked }) => {
  return (
    <div className="listTile">
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(title, id, e.target.checked)}
      />{" "}
      <span className="listTitle">{title}</span>
    </div>
  );
};

export default ListTile;
