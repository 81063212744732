import React, { useEffect, useState } from "react";
import { Alert, Button, Spinner, Table } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import PagePagination from "../../Components/Body/PagePagination";
import MainSearch from "../../Components/Body/MainSearch";
import { basicConfigMultipart } from "../../constants";

const per_page_limit = 10;

const AdminListProducts = () => {
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const search = urlParams.get("q");

  const page = urlParams.get("page") || 1;

  const [productsCount, setProductsCount] = useState(1);

  const [pages, setPages] = useState(1);

  const [loading, setLoading] = useState(true);

  const [showError, setShowError] = useState(false);

  const [productsList, setProductsList] = useState([]);

  useEffect(() => {
    const fetchProducts = async (search, per_page_limit) => {
      setShowError(false);
      setLoading(true);

      let formData = new FormData();

      formData.append("limit", per_page_limit);
      formData.append("page", page);

      if (search && search.trim().length > 0) {
        formData.append("q_search", search);
      }

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/filter/`,
          formData,
          basicConfigMultipart
        )
        .catch((_) => {
          setShowError(true);
        })
        .then((response) => {
          if (response && response.status === 200) {
            setProductsCount(response.data["count"]);
            setProductsList(response.data["products"]);
          } else {
            setShowError(true);
          }
        });

      setLoading(false);
    };

    fetchProducts(search, per_page_limit);
  }, [page, search]);

  useEffect(() => {
    setPages(Math.ceil(productsCount / per_page_limit));
  }, [productsCount]);

  return (
    <>
      {showError && (
        <Alert
          variant="danger"
          onClose={() => {
            setShowError(false);
          }}
          dismissible
        >
          <Alert.Heading>Error fetching.</Alert.Heading>
        </Alert>
      )}
      {loading && <Spinner animation="grow" />}
      <div className="admin_content">
        <div className="admin_content_header">
          <h4>Select Product to change</h4>
          <Link to={"/admin/product/add/"}>
            <Button>
              <Plus size={25} /> Add New
            </Button>
          </Link>
        </div>
        {/* place a searchbox here */}
        <MainSearch />

        <div className="admin_content_content">
          <Table striped hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {productsList.map((prod, index) => {
                return (
                  <tr key={index}>
                    <td>{prod.id}</td>
                    <td>
                      <Link to={`/admin/product/${prod.id}`}>{prod.name}</Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {/* pagination here */}
          <PagePagination page={parseInt(page) || 1} pages={pages} />
        </div>
      </div>
    </>
  );
};

export default AdminListProducts;
