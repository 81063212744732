import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/UserSlice";
import cartReducer from "./cart/CartSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    cart: cartReducer,
  },
  devTools: false,
});

export default store;
