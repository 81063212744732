import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CartContainer from "./CartContainer";
import { tallyCart } from "../../store/cart/CartActions";

const CartPage = () => {
  const { shopping_cart, sub_total } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tallyCart());
    // eslint-disable-next-line
  }, [shopping_cart]);

  return (
    <div className="cartPage">
      <Container>
        <Row>
          <Col sm={8} className="cartList">
            <h5>Shopping Cart ({(shopping_cart || []).length})</h5>
            <hr />
            {(shopping_cart || []).map((item, index) => {
              return <CartContainer key={index} item={item} />;
            })}
          </Col>
          <Col sm={3} className="cartSummary">
            <h5>Summary</h5>
            <hr />
            <Row>
              <Col sm={6}>Sub Total</Col>
              <Col sm={6}> Ksh. {sub_total} </Col>
            </Row>
            <br />
            <Button variant="success" disabled={!((sub_total || 0) > 0)}>
              Check Out
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CartPage;
