import React from "react";
import { Container } from "react-bootstrap";

const PageNotFound = () => {
  return (
    <Container style={{ minHeight: "81vh", padding: "100px 0" }}>
      <h2>404 Page Not Found</h2>
    </Container>
  );
};

export default PageNotFound;
