import React, { useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import { DataContext } from "../../Context/DataContext";
import PagePagination from "../../Components/Body/PagePagination";
import MainSearch from "../../Components/Body/MainSearch";

const per_page_limit = 10;

const AdminListCategories = () => {
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const search = urlParams.get("q");

  const page = urlParams.get("page") || 1;

  const { categoriesList } = useContext(DataContext);

  const [categoriesList_display, setCategoriesListDisplay] = useState([]);

  const [pages, setPages] = useState(1);

  useEffect(() => {
    if (search) {
      setCategoriesListDisplay(
        categoriesList.filter((c) =>
          c.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setCategoriesListDisplay(categoriesList);
    }
  }, [categoriesList, search]);

  useEffect(() => {
    setPages(Math.ceil(categoriesList_display.length / per_page_limit));
  }, [categoriesList_display]);

  return (
    <div className="admin_content">
      <div className="admin_content_header">
        <h4>Select Category to change</h4>
        <Link to={"/admin/category/add/"}>
          <Button>
            <Plus size={25} /> Add New
          </Button>
        </Link>
      </div>
      {/* place a searchbox here */}

      <MainSearch />

      <div className="admin_content_content">
        <Table striped hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {categoriesList_display
              .slice((page - 1) * per_page_limit, page * per_page_limit)
              .map((categ, index) => {
                return (
                  <tr key={index}>
                    <td>{categ.id}</td>
                    <td>
                      <Link to={`/admin/category/${categ.id}`}>
                        {categ.name}
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>

        {/* pagination here */}
        <PagePagination page={parseInt(page) || 1} pages={pages} />
      </div>
    </div>
  );
};

export default AdminListCategories;
