import React, { useContext, useEffect, useState } from "react";
import { ChevronRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ProductContainer from "../../Components/Body/ProductContainer";
import { Row } from "react-bootstrap";
import { placeholderProducts } from "../../CommonMethods";
import { DataContext } from "../../Context/DataContext";
import axios from "axios";
import { basicConfigMultipart } from "../../constants";

const LikeProducts = ({ product_categories }) => {
  // those items with same category list - sublist, or full list
  const { categoriesList } = useContext(DataContext);

  const [likeProducts, setLikeProducts] = useState([]);

  const [likeProductsFollowLink, setLikeProductsFollowLink] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchLikeProducts() {
      setLoading(true);

      if (product_categories && product_categories.length >= 1) {
        let foundAlike = [];
        let cList = product_categories.slice(); // making a copy
        let max_check = 0;
        // ** Limit 15 when fetching from api
        while (foundAlike.length < 2 && cList.length >= 1 && max_check < 7) {
          let formData = new FormData();

          formData.set("limit", 15);

          cList.forEach((element) => {
            formData.append("categ_ids", element.id);
          });

          await axios
            .post(
              `${process.env.REACT_APP_API_URL}/filter/`,
              formData,
              basicConfigMultipart
            )
            .catch((_) => {
              cList.pop();
            })
            .then((response) => {
              if (response && response.status === 200) {
                foundAlike = response.data.products;
                // if empty pop lowest categry
                if (foundAlike.length < 2) {
                  // if 1 then its that self product
                  cList.pop();
                } else {
                  setLikeProducts(foundAlike);
                }
              } else {
                cList.pop();
              }
            });
          max_check++;
        }

        let likeItemsFollowLink = "";

        for (const c of cList) {
          if (c.name === cList[0].name) {
            likeItemsFollowLink = c.name;
          } else {
            likeItemsFollowLink = likeItemsFollowLink.concat("-", c.name);
          }
        }

        setLikeProductsFollowLink(likeItemsFollowLink);
      }

      setLoading(false);
    }

    fetchLikeProducts();
  }, [product_categories, categoriesList]);

  return (
    <>
      {/* for those items with same category list, if empty, pop lowest lvl category, and fetch with products with same new list, LIMIT 20 */}
      {loading ? (
        <div className="categoryContainer">
          <div className="xy">
            <span className="pTitle">You May Also Like</span>
            <Link to=""></Link>
          </div>
          <hr />
          <Row className="listPart">{placeholderProducts(8)}</Row>
        </div>
      ) : (
        <>
          {likeProducts.length >= 1 ? (
            <div className="categoryContainer">
              <div className="xy">
                <span className="pTitle">You May Also Like</span>
                <Link to={`/${likeProductsFollowLink}/`}>
                  {/* send a state with lvl of category flow, e.g if product is samsung 42 inch tv, then send categories as 42`, Tel, Electronics */}
                  See All <ChevronRight size={24} />
                </Link>
              </div>
              <hr />
              <Row className="listPart">
                {likeProducts.map((prod, index) => {
                  return <ProductContainer key={index} product={prod} />;
                })}
              </Row>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default LikeProducts;
