import React from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import BrandComponent from "../BrandComponent";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activate } from "../../../store/user/UserActions";

const Activate = () => {
  const { uid, token } = useParams();

  const { loading, error } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const activateAccount = () => {
    dispatch(activate({ uid: uid, token: token })).then((result) => {
      navigate("/login");
    });
  };

  return (
    <div className="authpage">
      <Container>
        <Row>
          <BrandComponent></BrandComponent>
          <Col sm={6} style={{ margin: "auto" }}>
            {error && (
              <Alert variant="danger" autoFocus={true}>
                <Alert.Heading>{error}</Alert.Heading>
              </Alert>
            )}
            <h4>Verify Account</h4>
            <br />
            <Button onClick={activateAccount} style={{ width: "50%" }}>
              {loading ? "Activating..." : "Activate"}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Activate;
