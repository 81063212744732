import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import ListTile from "./ListTile";

const SearchSelectList = ({ items, keyName, selectedItems, setSelected }) => {
  const [search, setSearch] = useState("");
  const [itemsList, setList] = useState(items ? items : []);

  useEffect(() => {
    function searchList(val) {
      if (keyName) {
        if (val.trim().length !== 0) {
          let str = val.trim().toLowerCase();
          let aList = [];
          for (let index = 0; index < (items ? items : []).length; index++) {
            if (items[index][keyName].toLowerCase().includes(str)) {
              aList.push(items[index]);
            }
          }
          setList(aList);
        } else {
          setList(items ? items : []);
        }
      }
    }
    searchList(search);
  }, [search, items, keyName]);

  function handleChange(title, id, isChecked) {
    if (isChecked) {
      setSelected((l) => [...l, id]);
    } else {
      setSelected((l) => l.filter((n) => n !== id));
    }
  }
  return (
    <div className="filter-list">
      <InputGroup>
        <InputGroup.Text id="search-1">
          <Search />
        </InputGroup.Text>
        <Form.Control
          type="search"
          placeholder="Search...."
          className="me-2"
          aria-label="Search"
          aria-describedby="search-1"
          onChange={(e) => setSearch(e.target.value)}
        />
      </InputGroup>

      {items ? (
        <div className="itemsList">
          {itemsList.map((e, index) => {
            return (
              <ListTile
                key={index}
                id={e.id}
                title={e[keyName]}
                onChange={handleChange}
                checked={selectedItems ? selectedItems.includes(e.id) : false}
              />
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SearchSelectList;
