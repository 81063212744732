import axios from "axios";
import {
  AUTH_FAIL,
  AUTH_SUCCESS,
  LOAD_USER_FULFILLED,
  LOAD_USER_REJECTED,
  LOGIN_USER_FULFILLED,
  LOGIN_USER_PENDING,
  LOGIN_USER_REJECTED,
} from "./UserTypes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCart } from "../cart/CartActions";

export const checkIsAuthenticated = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const body = JSON.stringify({ token: localStorage.getItem("access") });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/jwt/verify/`,
        body,
        config
      );
      if (response && response.data.code !== "token_not_valid") {
        dispatch({
          type: AUTH_SUCCESS,
        });
        dispatch(loadUser());
      } else {
        dispatch({
          type: AUTH_FAIL,
        });
      }
    } catch (_) {
      dispatch({
        type: AUTH_FAIL,
      });
    }
  } else {
    dispatch({
      type: AUTH_FAIL,
    });
  }
};

export const loginUser =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch({
      type: LOGIN_USER_PENDING,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email, password });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/jwt/create/`,
        body,
        config
      );

      dispatch({
        type: LOGIN_USER_FULFILLED,
        payload: response.data,
      });

      //   on success, load user
      dispatch(loadUser());
    } catch (error) {
      dispatch({
        type: LOGIN_USER_REJECTED,
        error: error?.message || "Error! Try Again!",
      });
    }
  };

// export const loadUser = createAsyncThunk("user/loaduser", async () => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `JWT ${localStorage.getItem("access")}`,
//       Accept: "application/json",
//     },
//   };

//   const response = await axios.get(
//     `${process.env.REACT_APP_API_URL}/auth/users/me/`,
//     config
//   );

//   const res = await response.data;

//   return res;
// });

export const loadUser = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
      Accept: "application/json",
    },
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/auth/users/me/`,
      config
    );

    dispatch({
      type: LOAD_USER_FULFILLED,
      payload: response.data,
    });

    dispatch(fetchCart());
  } catch (error) {
    dispatch({
      type: LOAD_USER_REJECTED,
      error: "Error! Try Again!",
    });
  }
};

export const sign_up = createAsyncThunk(
  "user/signup",
  async ({ name, phone_number, email, password, re_password }) =>
    // async({ name, phone_number, email, password, re_password },{ dispatch })
    {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        email,
        name,
        password,
        re_password,
        phone_number,
      });

      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/auth/users/`, body, config)
        .catch((err) => {
          // console.log("Error", err.response.data);
          // {password: ['Too Common', 'Entirely Numeric']}
          let msg = "";

          for (const m of Object.values(err.response.data)[0]) {
            msg += `${m}. `;
          }

          return Promise.reject(msg);
        });

      const res = await response.data;

      return res;
    }
);

export const activate = createAsyncThunk(
  "user/activate",
  async ({ uid, token }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ uid, token });

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/users/activation/`,
      body,
      config
    );

    const res = await response.data;

    return res;
  }
);

export const sendResetPasswordRequest = createAsyncThunk(
  "user/reset_password_request",
  async ({ email }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email });

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/users/reset_password/`,
      body,
      config
    );

    const res = await response.data;

    return res;
  }
);

export const changePassword = createAsyncThunk(
  "user/change_password",
  async ({ uid, token, new_password, re_new_password }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    const response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`,
        body,
        config
      )
      .catch((err) => {
        // console.log("Error", err.response.data);
        // {password: ['Too Common', 'Entirely Numeric']}
        let msg = "";

        for (const m of Object.values(err.response.data)[0]) {
          msg += `${m}. `;
        }

        return Promise.reject(msg);
      });

    const res = await response.data;

    return res;
  }
);
