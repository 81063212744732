import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "./NavBar";

const ToggleNavBar = () => {
  const location = useLocation();

  const [limitNavBar, setLimitNavBar] = useState(true);

  useEffect(() => {
    if (
      location.pathname.startsWith("/admin") ||
      location.pathname.startsWith("/login") ||
      location.pathname.startsWith("/signup") ||
      location.pathname.startsWith("/activate") ||
      location.pathname.startsWith("/reset") ||
      location.pathname.startsWith("/password/reset")
    ) {
      setLimitNavBar(true);
    } else {
      setLimitNavBar(false);
    }
  }, [location]);

  return <NavBar limit={limitNavBar} />;
};

export default ToggleNavBar;
