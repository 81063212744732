import React, { useContext } from "react";
import { DataContext } from "../../Context/DataContext";
import { Link } from "react-router-dom";

const CategHorizList = () => {
  const { categoriesList } = useContext(DataContext);
  return (
    <div className="categ_horiz">
      <ul>
        {categoriesList
          .filter((c) => c.top_level)
          .map((category, i) => {
            return (
              <li>
                <Link to={`/${category?.name ?? ""}/`}>
                  {category?.name ?? ""}
                </Link>{" "}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default CategHorizList;
