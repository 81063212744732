import React, { useContext, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { DataContext } from "../../../../Context/DataContext";

const PBrandForm = ({ product, setProduct }) => {
  const { brandsList } = useContext(DataContext);

  const [typedBrand, setTypedBrand] = useState("");

  function handleBrand(str) {
    let found = false;
    for (const brnd of brandsList) {
      if (brnd.name.toLowerCase() === typedBrand.toLowerCase()) {
        found = true;
        setProduct({ ...product, brand: { ...brnd } });
      }
    }
    if (!found) {
      setProduct({ ...product, brand: { id: null, name: str } });
    }
  }

  const toggleDisplay = (id) => {
    const elem = document.getElementById(id);
    if (elem) {
      if (elem.style.display !== "block") {
        elem.style.setProperty("display", "block", "important");
      } else {
        elem.style.setProperty("display", "none", "important");
      }
    }
  };

  return (
    <Form.Group className="mb-3">
      <Form.Label>Brand </Form.Label>
      <div className="form-control-in-div">
        <h5>
          {product.brand ? product.brand.name : ""}
          <Form.Group>
            <Form.Control
              type="text"
              required
              value={product.brand ? product.brand.name : ""}
              name="brand"
              className="input_type_hidden"
              onChange={() => {}}
            />
            <Form.Control.Feedback type="invalid">
              Select or set brand
            </Form.Control.Feedback>
          </Form.Group>
        </h5>
        <div className="dropdown">
          <InputGroup>
            <Form.Control
              type="search"
              placeholder="Select brand or add.."
              aria-label="Search"
              aria-describedby="tags-1"
              className="no_validation_needed"
              onFocus={() => toggleDisplay("brands_add")}
              value={typedBrand}
              onChange={(e) => {
                setTypedBrand(e.target.value);
              }}
            />
            <InputGroup.Text id="brands-1">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  if (typedBrand.length > 1) {
                    handleBrand(typedBrand);
                    setTypedBrand("");
                  }
                }}
              >
                <Plus size={25} />
              </Button>
            </InputGroup.Text>
          </InputGroup>
          <div id="brands_add" className="drop-down-menu-custom">
            {brandsList.map((brand, index) => {
              if (brand.name.toLowerCase().includes(typedBrand.toLowerCase())) {
                return (
                  <button
                    className="drop-down-item-custom"
                    variant="link"
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      setProduct({ ...product, brand: { ...brand } });
                      setTypedBrand("");
                      toggleDisplay("brands_add");
                    }}
                  >
                    {brand.name}
                  </button>
                );
              }
              return <></>;
            })}
          </div>
        </div>
      </div>
    </Form.Group>
  );
};

export default PBrandForm;
