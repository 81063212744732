import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import AdminListProducts from "./AdminListProducts";
import AdminListCategories from "./AdminListCategories";
import { useNavigate, useParams } from "react-router-dom";
import AdminItemForm from "./forms/AdminItemForm";
import { useSelector } from "react-redux";
import AdminLogin from "./forms/AdminLoginForm";

const AdminPage = ({ addItem, changeItem }) => {
  const { is_staff } = useSelector((state) => state.user?.user ?? false);

  const { nm } = useParams();

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(-1);

  const adminPages = [
    { name: "Category", page: <AdminListCategories />, table: "category" },
    { name: "Product", page: <AdminListProducts />, table: "product" },
  ];

  const handleClick = (index, str) => {
    setCurrentPage(index);
    navigate(`/admin/${str}`);
  };

  useEffect(() => {
    if (nm) {
      let index = adminPages.findIndex((v) => {
        return v.table === nm.toLowerCase();
      });
      setCurrentPage(index);
    }
  }, [nm]);

  return (
    <>
      {is_staff ? (
        <div className="adminPage">
          <Container>
            <Row>
              <Col sm={4}>
                {adminPages.map((ele, index) => {
                  return (
                    <Button
                      key={index}
                      size="lg"
                      onClick={() => handleClick(index, ele.table)}
                      style={{
                        color: index === currentPage ? "black" : "grey",
                        backgroundColor:
                          index === currentPage ? "#e2e1e1" : "transparent",
                      }}
                    >
                      {ele.name}
                    </Button>
                  );
                })}
              </Col>
              {
                <Col sm={7}>
                  {currentPage >= 0 ? (
                    addItem || changeItem ? (
                      <AdminItemForm
                        tableName={adminPages[currentPage].table}
                      />
                    ) : (
                      <>{adminPages[currentPage].page}</>
                    )
                  ) : (
                    <center>Select Table</center>
                  )}
                </Col>
              }
            </Row>
          </Container>
        </div>
      ) : (
        <AdminLogin />
      )}
    </>
  );
};

export default AdminPage;
