import React from "react";
import Banner from "../../Components/Header/Banner";
import MainBody from "../../Components/Body/MainBody";
import { useLocation, useParams } from "react-router-dom";
import CategoryPage from "../category/CategoryPage";
import { Alert } from "react-bootstrap";

const Home = () => {
  // useParams -> Returns an object of key/value pairs of the dynamic params from the current URL that were matched by the route path.
  let { category } = useParams();

  const location = useLocation();

  return (
    <>
      {location.state?.notification && (
        <Alert
          className="alert-customizing"
          variant="success"
          autoFocus={true}
          dismissible
        >
          {location.state.notification}
        </Alert>
      )}
      {category ? (
        <CategoryPage categoryName={category} />
      ) : (
        <>
          <Banner />
          <MainBody />
        </>
      )}
    </>
  );
};

export default Home;
