import React, { useEffect, useState } from "react";
import CategoryForm from "./CategoryForm";
import ProductForm from "./ProductForm";

const AdminItemForm = ({ tableName }) => {
  // if pk, fecth that row from given table
  const [currentForm, setCurrentForm] = useState(-1);
  // list of forms
  const formsAvailable = [
    {
      tableName: "category",
      form: <CategoryForm />,
    },
    {
      tableName: "product",
      form: <ProductForm />,
    },
  ];

  useEffect(() => {
    let index = formsAvailable.findIndex((v) => {
      return v.tableName === tableName;
    });
    setCurrentForm(index);
  }, [tableName]);

  return <div>{currentForm >= 0 && formsAvailable[currentForm].form}</div>;
};

export default AdminItemForm;
