import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import BrandComponent from "../BrandComponent";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../../store/user/UserActions";

const ResetPasswordConfirm = () => {
  const { uid, token } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { loading, error } = useSelector((state) => state.user);

  const [formValidated, setFormValidated] = useState(false);

  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });

  const { new_password, re_new_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setFormValidated(true);
    } else {
      if (new_password === re_new_password) {
        setFormValidated(false);
        dispatch(
          changePassword({
            uid: uid,
            token: token,
            new_password: new_password,
            re_new_password: re_new_password,
          })
        ).then((result) => {
          if (result.payload) {
            navigate("/login");
          }
        });
      }
    }
  };

  return (
    <div className="authpage">
      <Container>
        <Row>
          <BrandComponent></BrandComponent>
          <Col sm={6} className="authform">
            {error && (
              <Alert variant="danger" autoFocus={true}>
                <Alert.Heading>{error}</Alert.Heading>
              </Alert>
            )}
            <h2>Reset Password</h2>
            <Form
              noValidate
              validated={formValidated}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Form.Group className="mb-3">
                <Form.Label>Password </Form.Label>
                <Form.Control
                  type="password"
                  name="new_password"
                  minLength={8}
                  required
                  value={new_password}
                  onChange={(e) => onChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  {new_password.length < 1
                    ? "Please Enter A Password."
                    : "Pasword Not less that 8 Characters"}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password </Form.Label>
                <Form.Control
                  type="password"
                  name="re_new_password"
                  isInvalid={new_password !== re_new_password}
                  className={
                    new_password.length > 0
                      ? new_password === re_new_password
                        ? "custom-validate-class-valid"
                        : "me-2  custom-validate-class-invalid"
                      : ""
                  }
                  required
                  value={re_new_password}
                  onChange={(e) => onChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  {re_new_password.length < 1
                    ? "Please Confirm Password."
                    : "Password Should Match"}
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                className="form-submit-btn"
                variant="primary"
                type="submit"
              >
                {loading ? "Resetting ....." : "Reset Password"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPasswordConfirm;
