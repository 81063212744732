import React from "react";
import ProductContainer from "../../Components/Body/ProductContainer";
import { Row } from "react-bootstrap";

const RelatedProducts = ({ related_products }) => {
  return (
    <>
      {related_products.length >= 1 ? (
        <div className="categoryContainer">
          <p className="pTitle"> Related Items</p>
          <hr />
          <Row className="listPart">
            {related_products.map((prod, index) => {
              return (
                <ProductContainer
                  key={index}
                  product={prod}
                  refetchItem={true}
                />
              );
            })}
          </Row>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default RelatedProducts;
