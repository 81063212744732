import React, { useContext, useEffect, useRef } from "react";
import { ThreeDots } from "react-bootstrap-icons";
import { DataContext } from "../../Context/DataContext";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const BannerLinksBox = ({ toggleDisplay, setToggleDisplay }) => {
  const { categoriesList } = useContext(DataContext);

  const divRef = useRef();

  const handleClickOutside = (e) => {
    if (!divRef.current.contains(e.target)) {
      setToggleDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <div
      className={
        toggleDisplay
          ? "bannerLinksBox show-bannerLinksBox "
          : "bannerLinksBox hide-bannerLinksBox "
      }
      ref={divRef}
    >
      <ul>
        {categoriesList
          .filter((c) => c.top_level)
          .slice(0, 8)
          .map((category, index) => {
            return (
              category.top_level && (
                <li>
                  <Link
                    key={index}
                    className="linkItem"
                    to={`/${category?.name ?? ""}/`}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}${category.image}`}
                      alt=""
                    />
                    <span className="linkItemName">
                      {" "}
                      {category?.name ?? ""}
                    </span>
                  </Link>

                  {category.sub_categories.length > 0 && (
                    <Dropdown>
                      <Dropdown.Toggle></Dropdown.Toggle>
                      <Dropdown.Menu>
                        {category.sub_categories.map((sub_categ, index) => {
                          return (
                            <Dropdown.Item key={index}>
                              <Link
                                className="linkItem"
                                to={`/${category.name}-${
                                  sub_categ?.name ?? ""
                                }/`}
                              >
                                {sub_categ?.name ?? ""}
                              </Link>
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </li>
              )
            );
          })}
        {categoriesList.filter((c) => c.top_level).length > 8 && (
          <li>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
              }}
              className="linkItem"
            >
              <ThreeDots />
              <span className="linkItemName"> More</span>
            </a>
            <Dropdown>
              <Dropdown.Toggle></Dropdown.Toggle>
              <Dropdown.Menu>
                {categoriesList
                  .filter((c) => c.top_level)
                  .slice(8, categoriesList.length)
                  .map((categ, index) => {
                    return (
                      <Dropdown.Item key={index}>
                        <Link className="linkItem" to={`/${categ.name}/`}>
                          {categ?.name ?? ""}
                        </Link>
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </li>
        )}
      </ul>
    </div>
  );
};

export default BannerLinksBox;
