import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import BrandComponent from "../BrandComponent";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../store/user/UserActions";
import { Navigate } from "react-router-dom";

const LogIn = () => {
  const [formValidated, setFormValidated] = useState(false);

  const dispatch = useDispatch();

  const { loading, isAuthenticated, error } = useSelector(
    (state) => state.user
  );

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  async function handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setFormValidated(true);
    } else {
      setFormValidated(false);
      dispatch(loginUser(formData));
    }
  }

  if (isAuthenticated) {
    return <Navigate to={"/"} />;
  }

  return (
    <div className="authpage">
      <Container>
        <Row>
          <BrandComponent></BrandComponent>
          <Col sm={6} className="authform">
            {error && (
              <Alert variant="danger" autoFocus={true}>
                {error}
              </Alert>
            )}
            <h2>Login</h2>
            <p>
              Don't have an account? <a href="/signup">SignUp</a>{" "}
            </p>
            <Form
              noValidate
              validated={formValidated}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Form.Group className="mb-3">
                <Form.Label>Email </Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  required
                  maxLength={64}
                  value={email}
                  onChange={(e) => onChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Email Address.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password </Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  required
                  value={password}
                  onChange={(e) => onChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Your Password.
                </Form.Control.Feedback>
              </Form.Group>
              <a href="/reset_password">Forgot Password?</a>
              <Button
                className="form-submit-btn"
                variant="primary"
                type="submit"
              >
                {loading ? "Loading..." : "Login"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LogIn;
