import React, { createContext, useState } from "react";

export const FilterContext = createContext();

const FilterContextProvider = (props) => {
  // filters

  const [minPrice, setMinPrice] = useState(0);

  const [maxPrice, setMaxPrice] = useState(500000);

  const [brands, setBrands] = useState([]);

  const [colors, setColors] = useState([]);

  const contextValues = {
    minPrice,
    maxPrice,
    brands,
    colors,
    setMinPrice,
    setMaxPrice,
    setBrands,
    setColors,
  };

  return (
    <FilterContext.Provider value={contextValues}>
      {props.children}
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;
