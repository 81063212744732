export const basicConfig = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${process.env.REACT_APP_TOKEN}`,
  },
};

export const basicConfigMultipart = {
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Token ${process.env.REACT_APP_TOKEN}`,
  },
};
