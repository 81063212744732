import React, { useEffect, useState } from "react";
import logo from "../../logo.svg";
import {
  Container,
  Navbar,
  Nav,
  Form,
  Button,
  NavDropdown,
  InputGroup,
  Badge,
  Offcanvas,
  FormControl,
} from "react-bootstrap";
import { Cart, Headset, Heart, Person, Search } from "react-bootstrap-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/user/UserSlice";
import { loginUser } from "../../store/user/UserActions";
import HoveringLoginRegister from "./HoveringLoginRegister";
import HoveringSearchBar from "./HoveringSearchBar";
import HoveringCartTotal from "./HoveringCartTotal";

const NavBar = ({ limit = false }) => {
  const navigate = useNavigate();

  const location = useLocation();

  const dispatch = useDispatch();

  const { isAuthenticated, user, error } = useSelector((state) => state.user);

  const { shopping_cart } = useSelector((state) => state.cart);

  const urlParams = new URLSearchParams(location.search);

  const [search, setSearch] = useState("");
  const [scrolled, setScrolled] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 540) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  function handleSearch(e) {
    e.preventDefault();
    if (location.pathname === "/") {
      navigate(`/catalog/?q=${search}`);
    } else {
      urlParams.set("q", search);
      navigate(`?${urlParams.toString()}`);
    }
  }

  async function signInUser(e) {
    e.preventDefault();
    dispatch(loginUser(formData));
  }

  return (
    <>
      <Navbar expand="sm" className={scrolled ? "scrolled" : ""}>
        <Container fluid>
          <Link to={"/"} className="navbar-brand">
            <img src={logo} alt="Logo" />
          </Link>

          {limit ? (
            isAuthenticated ? (
              <Nav.Link
                onClick={() => {
                  dispatch(logout());
                  navigate("/");
                }}
              >
                Logout
              </Nav.Link>
            ) : (
              <Nav.Link as={Link} to="/login">
                Log In
              </Nav.Link>
            )
          ) : (
            <>
              {/* <Navbar.Toggle aria-controls="basic-navbar-nav">
                  <span className="navbar-toggler-icon"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="justify-content-end flex-grow-1 spacer">
                    <Form className="d-flex" onSubmit={(e) => handleSearch(e)}>
                      <InputGroup>
                        <InputGroup.Text id="search-1">
                          <Search />
                        </InputGroup.Text>
                        <Form.Control
                          type="search"
                          placeholder="Search products, categories and brands"
                          className="me-2"
                          aria-label="Search"
                          aria-describedby="search-1"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </InputGroup>
                      <Button variant="success" type="submit">
                        Search
                      </Button>
                    </Form>

                    <div className="drpDown">
                      <Person
                        size={28}
                        style={{
                          padding:
                            "var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x)",
                          height: "38px",
                        }}
                      />
                      <NavDropdown title="Account" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">
                          <Person size={22} />{" "}
                          {isAuthenticated && user != null
                            ? user.email
                            : " My Account"}
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#order">
                          <Receipt size={21} /> Orders
                        </NavDropdown.Item>

                        <NavDropdown.Divider />
                        <NavDropdown.Item className="signIn">
                          <Button
                            variant="success"
                            onClick={
                              isAuthenticated
                                ? () => dispatch(logout())
                                : () => navigate("/login")
                            }
                            style={{ display: "block" }}
                          >
                            {isAuthenticated ? "Log Out" : "Sign In"}
                          </Button>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                    <Nav.Link as={Link} to="/cart">
                      <Cart size={24} />
                      <Badge bg="danger">{(shopping_cart || []).length}</Badge>
                      Cart
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse> */}

              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-sm`}
                aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                    Menu
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="flex-grow-1 pe-3 justify-content-around">
                    <Form className="d-flex" onSubmit={(e) => handleSearch(e)}>
                      <InputGroup>
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <InputGroup.Text>
                          <Button variant="outline-success" type="submit">
                            <Search size={22} color="black" fontWeight="bold" />
                          </Button>
                        </InputGroup.Text>
                      </InputGroup>
                    </Form>
                    <div className="navSideActions">
                      <Nav.Link className="nav_contact">
                        <Headset size={40} />
                        <div style={{ textAlign: "left", marginLeft: "8px" }}>
                          Call us 24/7 <br />
                          <b>(0715) 862-727</b>
                        </div>
                      </Nav.Link>
                      <NavDropdown
                        title={<Person size={25} />}
                        id={`offcanvasNavbarDropdown-expand-sm`}
                        className="custom_navbar_dropdown"
                      >
                        {isAuthenticated ? (
                          <>
                            <NavDropdown.ItemText>
                              <Link>{user?.email}</Link>
                            </NavDropdown.ItemText>
                            <NavDropdown.ItemText>
                              {/* <Link to={"#"}>My Orders</Link> */}
                            </NavDropdown.ItemText>
                            <NavDropdown.Divider />
                            <NavDropdown.ItemText>
                              <Button
                                onClick={() => {
                                  dispatch(logout());
                                  navigate("/");
                                }}
                              >
                                LOG OUT
                              </Button>
                            </NavDropdown.ItemText>
                          </>
                        ) : (
                          <>
                            <Form onSubmit={(e) => signInUser(e)}>
                              {error && (
                                <NavDropdown.ItemText style={{ color: "red" }}>
                                  Validation Error!
                                </NavDropdown.ItemText>
                              )}
                              <NavDropdown.ItemText>
                                <FormControl
                                  type="text"
                                  name="email"
                                  placeholder="Email"
                                  value={email}
                                  required
                                  onChange={(e) => onChange(e)}
                                />
                              </NavDropdown.ItemText>
                              <NavDropdown.ItemText>
                                <FormControl
                                  type="password"
                                  name="password"
                                  placeholder="Password"
                                  value={password}
                                  required
                                  onChange={(e) => onChange(e)}
                                />
                              </NavDropdown.ItemText>

                              <NavDropdown.ItemText>
                                <Button type="submit">SIGN IN</Button>
                              </NavDropdown.ItemText>
                            </Form>
                            <NavDropdown.Divider />
                            <NavDropdown.ItemText>
                              <Link to={"/signup"}>No Account? Sign Up</Link>
                            </NavDropdown.ItemText>
                          </>
                        )}
                      </NavDropdown>

                      <Nav.Link href="#wishlist">
                        <Heart size={25} />
                      </Nav.Link>
                      <Nav.Link href="/cart">
                        <Cart size={25} />
                        {(shopping_cart || []).length > 0 && (
                          <Badge bg="danger">
                            {(shopping_cart || []).length}
                          </Badge>
                        )}
                      </Nav.Link>
                    </div>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </>
          )}

          {/* <Row id="collapsed-search">
          <Form className="d-flex" onSubmit={(e) => handleSearch(e)}>
            <Form.Control
              type="search"
              placeholder="Search products, categories and brands"
              className="me-2"
              aria-label="Search"
              aria-describedby="search-1"
              onChange={(e) => setSearch(e.target.value)}
            />

            <Button variant="success" type="submit">
              <Search />
            </Button>
          </Form>
        </Row> */}
        </Container>
      </Navbar>
      <div className="phone_actions">
        <HoveringLoginRegister />
        {!location.pathname.startsWith("/admin") && <HoveringSearchBar />}
        {!location.pathname.startsWith("/admin") && <HoveringCartTotal />}
      </div>
    </>
  );
};

export default NavBar;
