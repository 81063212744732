import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import CategoryContainer from "./CategoryContainer";
import { DataContext } from "../../Context/DataContext";

const MainBody = () => {
  //     images: require("../../assets/images/products/1.png"),

  const { categoriesList } = useContext(DataContext);

  return (
    <div className="mainBody">
      <Container>
        {/*
         ***Three sections for NEW, Trending and Flash Sale
         */}
        {categoriesList.map((category, index) => {
          return (
            category.top_level && (
              <CategoryContainer key={index} category={category} />
            )
          );
        })}
      </Container>
    </div>
  );
};

export default MainBody;
