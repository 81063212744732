import React from "react";
import { Button, Form } from "react-bootstrap";
import { Dash, Plus } from "react-bootstrap-icons";
import { preventScrollOnInput } from "../../CommonMethods";

const MinusPlusContainer = ({ val, onChange, onAdd, onMinus }) => {
  return (
    <div className="minus-plus-container">
      <Button variant="outline-secondary" onClick={onMinus}>
        <Dash size={25} />
      </Button>
      <Form.Control
        type="number"
        onFocus={preventScrollOnInput}
        value={val}
        onChange={onChange}
      />
      <Button variant="outline-secondary" onClick={onAdd}>
        <Plus size={25} />
      </Button>
    </div>
  );
};

export default MinusPlusContainer;
