import React from "react";
import fullStar from "../../assets/images/full-star.svg";
import halfStar from "../../assets/images/half-star.svg";
import emptyStar from "../../assets/images/empty-star.svg";

const StarRating = ({ value }) => {
  let starList = [];

  for (let index = 1; index < 6; index++) {
    if (value >= index) {
      starList.push(
        <span key={index} className="star">
          <img src={fullStar} alt="" />
        </span>
      );
    } else {
      if (Math.ceil(value) >= index) {
        starList.push(
          <span key={index} className="star">
            <img src={halfStar} alt="" />
          </span>
        );
      } else {
        starList.push(
          <span key={index} className="star">
            <img src={emptyStar} alt="" />
          </span>
        );
      }
    }
  }
  return <div style={{ display: "flex", margin: "8px 0" }}>{starList}</div>;
};

export default StarRating;
