import axios from "axios";
import React, { createContext, useState } from "react";
import { useEffect } from "react";
import { basicConfigMultipart } from "../constants";

export const DataContext = createContext();

const DataContextProvider = ({ children }) => {
  const [categoriesList, setCategoriesList] = useState([]);

  const [brandsList, setBrandsList] = useState([]);

  const [colorsList, setColorsList] = useState([]);

  // function that sets brands,

  async function fetchBrands() {
    const response = await axios
      .get(`${process.env.REACT_APP_API_URL}/brands/`)
      .catch((error) => {});

    if (response && response.status === 200) {
      setBrandsList(await response.data);
    }
  }

  async function fetchCategories() {
    const response = await axios
      .get(`${process.env.REACT_APP_API_URL}/category/`)
      .catch((error) => {});

    if (response && response.status === 200) {
      setCategoriesList(await response.data);
    }
  }

  async function fetchColors() {
    const response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/filter_meta/`,
        {
          key: "color",
        },
        basicConfigMultipart
      )
      .catch((err) => {});
    if (response && response.status === 200) {
      setColorsList(await response.data);
    }
  }

  useEffect(() => {
    fetchBrands();
    fetchCategories();
    fetchColors();
  }, []);

  const contextValues = {
    categoriesList,
    fetchCategories,
    brandsList,
    fetchBrands,
    colorsList,
    fetchColors,
  };

  return (
    <DataContext.Provider value={contextValues}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContextProvider;

// const resp = await axios.post(UPLOAD_ENDPOINT, formData, {
//     headers: {
//       "content-type": "multipart/form-data",
//       Authorization: `Bearer ${userInfo.token}`,
//     },
// });

// const URL = "http://localhost:8000";

// const [productList, setProdList] = useState([]);

// // without providing a list to useEffect, the function will run infinitely bcuz of the setState
// useEffect(() => {
//   async function fetchProducts() {
//     //using fetch()
//     // const response = await fetch(URL).catch((err) => {
//
//     // });

//     // if (response && response.ok) {
//     //   const data = await response.json();

//     //   setProdList(data);
//     // }

//     // using axios
//     const response = await axios.get(URL).catch((err) => {
//     });

//     if (response && response.status === 200) {
//       setProdList(await response.data);
//     }
//   }
//   fetchProducts();
// }, []);
