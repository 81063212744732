import axios from "axios";
import LoadingProductsContainer from "./Components/Body/LoadingProductsContainer";
import { basicConfigMultipart } from "./constants";

export const checkSubset = (parentArray, subsetArray) => {
  return subsetArray.every((el) => {
    return parentArray.includes(el);
  });
};

export const randomNumberInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export function placeholderProducts(intValue) {
  let containers = [];

  for (let index = 0; index < intValue; index++) {
    containers.push(<LoadingProductsContainer key={index} />);
  }

  return containers;
}

export function isArray(o) {
  return Object.prototype.toString.call(o) === "[object Array]";
}

export function validateEmail(str) {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-]+$/.test(
    str
  );
}

export function validatePhoneNumber(num) {
  return /^(?:[+0])[0-9]{9,16}$/.test(num);
}

export const preventScrollOnInput = (e) =>
  e.target.addEventListener(
    "wheel",
    function (e) {
      e.preventDefault();
    },
    { passive: false }
  );

export async function return_ids_from_objects(obj, api_link, if_update) {
  let id = null;
  if (obj && obj.id) {
    // has id
    if (if_update) {
      // update table row
      const formData = new FormData();

      for (const [key, value] of Object.entries(obj)) {
        if (value != null) {
          if (!isArray(value)) {
            formData.append(`${key}`, value);
          }
        }
      }

      await axios
        .put(`${api_link}${obj.id}/`, formData, basicConfigMultipart)
        .catch((_) => {})
        .then((r) => {
          if (r && r.status === 200) {
            id = r.data.id;
          }
        });
    } else {
      id = obj.id;
    }
  } else {
    // no id, posting as new object
    const formData = new FormData();

    for (const [key, value] of Object.entries(obj)) {
      if (value != null) {
        if (!isArray(value)) {
          formData.append(`${key}`, value);
        }
      }
    }

    await axios
      .post(api_link, formData, basicConfigMultipart)
      .catch((_) => {})
      .then((r) => {
        if (r && r.status === 200) {
          id = r.data.id;
        }
      });
  }
  return id;
}
