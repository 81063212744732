import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import MinusPlusContainer from "../../Components/Body/MinusPlusContainer";
import { useDispatch } from "react-redux";
import { alterCart, deleteCart } from "../../store/cart/CartActions";

const CartContainer = ({ item }) => {
  const { id, prod_id, quantity, meta_data } = item;

  const dispatch = useDispatch();

  const [fetching, setFetching] = useState(true);

  const [percentOff, setPercentOff] = useState(0);

  const [product, setProduct] = useState(null);

  const onChange = (e) => {
    if (parseInt(e.target.value)) {
      if (e.target.value > 0) {
        dispatch(
          alterCart({
            id: id,
            prod_id: prod_id,
            quantity: parseInt(e.target.value),
            meta_data: meta_data,
          })
        );
      }
    }
  };

  const onAdd = () => {
    dispatch(
      alterCart({
        id: id,
        prod_id: prod_id,
        quantity: quantity + 1,
        meta_data: meta_data,
      })
    );
  };

  const onMinus = () => {
    if (quantity > 1) {
      dispatch(
        alterCart({
          id: id,
          prod_id: prod_id,
          quantity: quantity - 1,
          meta_data: meta_data,
        })
      );
    } else {
      dispatch(deleteCart({ id: id, prod_id: prod_id }));
    }
  };

  useEffect(() => {
    function calculatePercentOff() {
      let perc = 0;
      if (product) {
        if (product.price && product.regular_price) {
          perc =
            ((product.regular_price - product.price) / product.regular_price) *
            100;
        }
      }

      return perc;
    }
    setPercentOff(calculatePercentOff());
  }, [product]);

  useEffect(() => {
    async function fetchProdOnline() {
      setFetching(true);
      await axios
        .get(`${process.env.REACT_APP_API_URL}/product/${prod_id}/`)
        .catch((_) => {})
        .then((response) => {
          if (response && response.status === 200) {
            setProduct(response.data);
          }
        });

      setFetching(false);
    }

    fetchProdOnline();
  }, [prod_id]);

  return (
    <div className="cartContainer">
      {fetching ? (
        <Row className="cartContainer-loading">
          <Col sm={3} className="loading-cart-image">
            <img alt="" />
          </Col>
          <Col sm={8}>
            <div className="loading-cart-content-2">
              <img alt="" />
            </div>
            <div className="loading-cart-content-3">
              <img alt="" />
            </div>
          </Col>
        </Row>
      ) : (
        product && (
          <Row className="cartDetails">
            <Col sm={3}>
              <img
                src={`${process.env.REACT_APP_API_URL}${
                  product.images[0]?.src ?? ""
                }`}
                alt={product.images[0]?.alt ?? ""}
              />
            </Col>
            <Col sm={6}>
              <p>{product.name}</p>
              {product.purchasable ? (
                <>
                  <p
                    style={{
                      color:
                        product.stock_status === "instock"
                          ? "green"
                          : product.backorders_allowed
                          ? "green"
                          : "#aa1111ab",
                    }}
                  >
                    {product.backorders_allowed
                      ? "instock"
                      : product.stock_status}
                  </p>
                  {!product.backorders_allowed && (
                    <p>Stock : {product.stock_quantity}</p>
                  )}
                </>
              ) : (
                <p style={{ color: "yellow" }}>Not Available</p>
              )}
            </Col>
            <Col sm={3}>
              <p style={{ fontWeight: "bold" }}>Ksh {product.price}</p>
              {percentOff > 0 ? (
                <p>
                  <span style={{ textDecoration: "line-through" }}>
                    {product.regular_price}
                  </span>{" "}
                  <span style={{ color: "red" }}>
                    -{percentOff.toFixed(1)}%
                  </span>
                </p>
              ) : (
                <></>
              )}

              <MinusPlusContainer
                val={quantity}
                onChange={onChange}
                onAdd={onAdd}
                onMinus={onMinus}
              />

              <Link
                as={Button}
                style={{ color: "red" }}
                onClick={() =>
                  dispatch(deleteCart({ id: id, prod_id: prod_id }))
                }
              >
                <Trash /> Delete
              </Link>
            </Col>
          </Row>
        )
      )}
    </div>
  );
};

export default CartContainer;
