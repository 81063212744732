import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Dropdown,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Plus } from "react-bootstrap-icons";
import PBrandForm from "./ProductFormComponents/PBrandForm";
import PCategoryForm from "./ProductFormComponents/PCategoryForm";
import PMetaDataForm from "./ProductFormComponents/PMetaDataForm";
import PTagsForm from "./ProductFormComponents/PTagsForm";
import PImageForm from "./ProductFormComponents/PImageForm";
import axios from "axios";
import { DataContext } from "../../../Context/DataContext";
import {
  isArray,
  preventScrollOnInput,
  return_ids_from_objects,
} from "../../../CommonMethods";
import { useSelector } from "react-redux";
import { basicConfigMultipart } from "../../../constants";

const productModel = {
  id: null,
  name: "",
  model: "",
  whats_in_box: "",
  description: "",
  short_description: "",
  features: "",
  price: "",
  regular_price: "",
  sale_price: "",
  featured: false,
  sold_individually: true,
  on_sale: true,
  purchasable: true,
  manage_stock: true,
  stock_quantity: "",
  stock_status: "instock",
  backorders_allowed: true,
  weight: "",
  reviews_allowed: true,
  average_rating: 0,
  rating_count: 0,
  tags: "",
  brand: null,
  done_by: "",
  dimensions: {
    id: null,
    height: "",
    width: "",
    length: "",
  },
  downloads: [],
  related_ids: [],
  categories: [],
  images: [],
  meta_data: [],
};

const stockStatuses = [
  { key: "IN STOCK", value: "instock" },
  { key: "OUT-OF-STOCK", value: "outofstock" },
  { key: "ON-BACKORDER", value: "onbackorder" },
];

const ProductForm = () => {
  const { pk } = useParams();

  const navigate = useNavigate();

  const { fetchBrands, fetchColors, fetchCategories } = useContext(DataContext);

  const [loading, setLoading] = useState(false);

  const [showError, setShowError] = useState(false);

  const [product, setProduct] = useState(productModel);

  const [formValidated, setFormValidated] = useState(false);

  const { user } = useSelector((state) => state.user);

  async function fetchProductById(id) {
    setShowError(false);
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/product/${id}/`)
      .catch((_) => {
        setShowError(true);
      })
      .then((response) => {
        if (response && response.status === 200) {
          setProduct({ ...productModel, ...response.data });
        } else {
          setShowError(true);
        }
      });

    setLoading(false);
  }
  useEffect(() => {
    //   fetch the item using pk as id
    if (pk) {
      fetchProductById(pk);
    }
  }, [pk]);

  useEffect(() => {
    let i = stockStatuses.findIndex((p) => {
      return p.value === product.stock_status;
    });
    if (i >= 0) {
      setStockStatus(i);
    }
  }, [product.stock_status]);

  async function handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setFormValidated(true);
      window.scrollTo(0, 0);
    } else {
      setShowError(false);
      setFormValidated(false);
      setLoading(true);

      // what we need to do,
      // convert list of {} or {} into ids, -> fn that returns id if key of id has non-null value, otherwise creates object in table and return id
      // for product, object that will be affected
      // brand, dimensions, related_ids, categories, images, metadata
      // file objects will need a different fn
      let modified_product = { ...product };

      let categories_ids = [];
      for (const obj of modified_product.categories) {
        const id = await return_ids_from_objects(
          obj,
          `${process.env.REACT_APP_API_URL}/category/`,
          false
        );
        if (id !== null) {
          categories_ids.push(id);
        }
      }

      modified_product.categories = categories_ids;

      let meta_data_ids = [];
      for (const obj of modified_product.meta_data) {
        const id = await return_ids_from_objects(
          obj,
          `${process.env.REACT_APP_API_URL}/metadata/`,
          false
        );
        if (id !== null) {
          meta_data_ids.push(id);
        }
      }

      modified_product.meta_data = meta_data_ids;

      let images_ids = [];
      for (const obj of modified_product.images) {
        const id = await return_ids_from_objects(
          obj,
          `${process.env.REACT_APP_API_URL}/images/`,
          false
        );
        if (id !== null) {
          images_ids.push(id);
        }
      }

      modified_product.images = images_ids;

      modified_product.brand = await return_ids_from_objects(
        product.brand,
        `${process.env.REACT_APP_API_URL}/brands/`,
        false
      );

      modified_product.dimensions = await return_ids_from_objects(
        product.dimensions,
        `${process.env.REACT_APP_API_URL}/dimensions/`,
        true
      );

      const formData = new FormData();

      for (const [key, value] of Object.entries(modified_product)) {
        if (value != null) {
          //   image sent should be a file not a string, so we'll append image elsewhere
          if (isArray(value)) {
            value.forEach((i) => {
              formData.append(`${key}`, i);
            });
          } else {
            formData.append(`${key}`, value);
          }
        }
      }

      // let slug = modified_product.name.toLowerCase();

      // formData.set("slug", slug);

      formData.set("done_by", user.email);

      if (pk) {
        //   updating
        await axios
          .put(
            `${process.env.REACT_APP_API_URL}/product/${pk}/`,
            formData,
            basicConfigMultipart
          )
          .catch((_) => {
            setShowError(true);
          })
          .then((response) => {
            if (response && response.status === 200) {
              fetchBrands();
              fetchColors();
              fetchCategories();
              navigate("/admin/product");
            } else {
              setShowError(true);
            }
          });
      } else {
        // posting
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/`,
            formData,
            basicConfigMultipart
          )
          .catch((_) => {
            setShowError(true);
          })
          .then((response) => {
            if (response && response.status === 200) {
              fetchBrands();
              fetchColors();
              fetchCategories();
              navigate("/admin/product");
            } else {
              setShowError(true);
            }
          });
      }
      setLoading(false);
    }
  }

  const [selectedStockStatus, setStockStatus] = useState(0);

  return (
    <>
      {showError && (
        <Alert
          variant="danger"
          autoFocus={true}
          onClose={() => {
            setShowError(false);
          }}
          dismissible
        >
          {window.scrollTo(0, 0)}
          <Alert.Heading>Error. Try Again.</Alert.Heading>
        </Alert>
      )}
      {loading && <Spinner animation="grow" />}
      <Form
        noValidate
        validated={formValidated}
        onSubmit={(e) => handleSubmit(e)}
        encType="multipart/form-data"
      >
        <Form.Group className="mb-3">
          <Form.Label>Name </Form.Label>
          <Form.Control
            type="text"
            required
            maxLength={64}
            value={product.name ?? ""}
            onChange={(e) => {
              setProduct({
                ...product,
                name: e.target.value.trimStart(),
                slug: (e.target.value.trimStart() ?? "").toLowerCase(),
              });
            }}
          />
          <Form.Control.Feedback type="invalid">
            Please type product name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            required
            className="form-control"
            maxLength={800}
            value={product.description ?? ""}
            rows={4}
            onChange={(e) =>
              setProduct({
                ...product,
                description: e.target.value.trimStart(),
              })
            }
          ></textarea>
          <Form.Control.Feedback type="invalid">
            Please provide a full description.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <label htmlFor="short_desc">Short Description</label>
          <textarea
            name="short_desc"
            required
            maxLength={200}
            className="form-control"
            value={product.short_description ?? ""}
            rows={2}
            onChange={(e) =>
              setProduct({
                ...product,
                short_description: e.target.value.trimStart(),
              })
            }
          ></textarea>
          <Form.Control.Feedback type="invalid">
            Please provide a short description.
          </Form.Control.Feedback>
        </Form.Group>

        <PBrandForm product={product} setProduct={setProduct} />

        <Form.Group className="mb-3">
          <Form.Label>Model</Form.Label>
          <Form.Control
            type="text"
            value={product.model ?? ""}
            onChange={(e) =>
              setProduct({ ...product, model: e.target.value.trimStart() })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <label htmlFor="whats_in_box">What's In Box:</label>
          <textarea
            name="whats_in_box"
            className="form-control"
            value={product.whats_in_box ?? ""}
            rows={4}
            onChange={(e) =>
              setProduct({
                ...product,
                whats_in_box: e.target.value.trimStart(),
              })
            }
          ></textarea>
        </Form.Group>
        <Form.Group className="mb-3">
          <label htmlFor="features">Features</label>
          <textarea
            name="features"
            className="form-control"
            value={product.features ?? ""}
            rows={4}
            onChange={(e) =>
              setProduct({ ...product, features: e.target.value.trimStart() })
            }
          ></textarea>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Price </Form.Label>
          <Form.Control
            type="number"
            onFocus={preventScrollOnInput}
            required
            value={product.price ?? ""}
            onChange={(e) =>
              setProduct({
                ...product,
                price: e.target.value,
                sale_price: e.target.value,
              })
            }
          />
          <Form.Control.Feedback type="invalid">
            Please provide product price
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Regular Price (Before Discount) </Form.Label>
          <Form.Control
            type="number"
            onFocus={preventScrollOnInput}
            required
            value={product.regular_price ?? ""}
            onChange={(e) =>
              setProduct({
                ...product,
                regular_price: e.target.value,
              })
            }
          />
          <Form.Control.Feedback type="invalid">
            Please provide product regular price
          </Form.Control.Feedback>
        </Form.Group>
        {/* <Form.Group className="mb-3">
          <Form.Label>Sale Price </Form.Label>
          <Form.Control
            type="number"
            onFocus={preventScrollOnInput}
            required
            value={product.sale_price || ""}
            
            // onChange={(e) =>
            //   setProduct({ ...product, sale_price: e.target.value })
            // }
          />
          <Form.Control.Feedback type="invalid">
            Please provide product sale price
          </Form.Control.Feedback>
        </Form.Group> */}

        <PImageForm product={product} setProduct={setProduct} />

        {/* <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="On-Sale"
            checked={product.on_sale ?? true}
            onChange={(e) =>
              setProduct({ ...product, on_sale: e.target.checked })
            }
          />
        </Form.Group> */}
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Purchaseable"
            checked={product.purchasable ?? true}
            onChange={(e) =>
              setProduct({ ...product, purchasable: e.target.checked })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Featured"
            checked={product.featured ?? false}
            onChange={(e) =>
              setProduct({ ...product, featured: e.target.checked })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Stock Quantity </Form.Label>
          <Form.Control
            type="number"
            onFocus={preventScrollOnInput}
            required
            value={product.stock_quantity}
            onChange={(e) =>
              setProduct({ ...product, stock_quantity: e.target.value })
            }
          />
          <Form.Control.Feedback type="invalid">
            Provide an est. stock quantity
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Stock Status </Form.Label>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              {stockStatuses[selectedStockStatus].key}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {stockStatuses.map((s, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      setProduct({ ...product, stock_status: s.value });
                    }}
                  >
                    {s.key}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Backorders Allowed"
            checked={product.backorders_allowed ?? true}
            onChange={(e) =>
              setProduct({ ...product, backorders_allowed: e.target.checked })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Weight </Form.Label>
          <Form.Control
            type="text"
            value={product.weight ? product.weight : ""}
            onChange={(e) =>
              setProduct({ ...product, weight: e.target.value.trimStart() })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Dimensions </Form.Label>
          <div className="form-control-in-div">
            <Form.Group className="mb-3">
              <Form.Label>Height </Form.Label>
              <Form.Control
                type="text"
                value={product.dimensions && product.dimensions.height}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    dimensions: {
                      ...product.dimensions,
                      height: e.target.value.trimStart(),
                    },
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Width </Form.Label>
              <Form.Control
                type="text"
                value={product.dimensions && product.dimensions.width}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    dimensions: {
                      ...product.dimensions,
                      width: e.target.value.trimStart(),
                    },
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Breadth </Form.Label>
              <Form.Control
                type="text"
                value={product.dimensions && product.dimensions.length}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    dimensions: {
                      ...product.dimensions,
                      length: e.target.value.trimStart(),
                    },
                  })
                }
              />
            </Form.Group>
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Avg. Rating </Form.Label>
          <Form.Control
            type="number"
            onFocus={preventScrollOnInput}
            min={0}
            max={5}
            value={product.average_rating ?? 0}
            onChange={(e) => {
              if (e.target.value >= 0 && e.target.value <= 5) {
                setProduct({ ...product, average_rating: e.target.value });
              }
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Rating Count </Form.Label>
          <Form.Control
            type="number"
            onFocus={preventScrollOnInput}
            min={0}
            value={product.rating_count ?? 0}
            onChange={(e) =>
              setProduct({ ...product, rating_count: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Related Products </Form.Label>
          <div className="form-control-in-div">
            <InputGroup>
              <Form.Control
                type="search"
                placeholder="Type to Search product..."
                className="me-2 no_validation_needed"
                aria-label="Search"
                aria-describedby="related-prod"
              />
              <InputGroup.Text id="related-prod">
                <Button variant="outline-secondary">
                  <Plus size={25} />
                </Button>
              </InputGroup.Text>
            </InputGroup>
          </div>
        </Form.Group>

        <PCategoryForm product={product} setProduct={setProduct} />

        <PTagsForm product={product} setProduct={setProduct} />
        <PMetaDataForm product={product} setProduct={setProduct} />

        <Button className="form-submit-btn" variant="primary" type="submit">
          Save
        </Button>
      </Form>
    </>
  );
};

export default ProductForm;
