import { createSlice } from "@reduxjs/toolkit";
import { alterCart, deleteCart, fetchCart, tallyCart } from "./CartActions";

const initialState = {
  loading: false,
  shopping_cart: null,
  sub_total: null,
  error: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCart.pending, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.loading = false;
        state.shopping_cart = action.payload;
      })
      .addCase(fetchCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message || "Error Loading Cart";
      })
      // alter cart
      .addCase(alterCart.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(alterCart.fulfilled, (state, action) => {
        state.loading = false;
        state.shopping_cart = action.payload;
      })
      .addCase(alterCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message || "Error Altering Cart";
      }) // delete cart
      .addCase(deleteCart.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCart.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message || "Error Deleting Cart";
      })
      // tally cart
      .addCase(tallyCart.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(tallyCart.fulfilled, (state, action) => {
        state.loading = false;
        state.sub_total = action.payload;
      })
      .addCase(tallyCart.rejected, (state, action) => {
        state.loading = false;
        state.sub_total = 0;
      });
  },
});

export default cartSlice.reducer;
