import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ChevronRight } from "react-bootstrap-icons";
import ProductContainer from "./ProductContainer";
import { Link } from "react-router-dom";
import { placeholderProducts } from "../../CommonMethods";
import axios from "axios";

const CategoryContainer = ({ category }) => {
  const [loading, setLoading] = useState(true);

  const [categoryProducts, setCategoryProducts] = useState([]);

  useEffect(() => {
    async function fetchCategProducts() {
      setLoading(true);

      await axios
        .get(`${process.env.REACT_APP_API_URL}/filter/?cat_id=${category.id}`)
        .catch((_) => {})
        .then((response) => {
          if (response && response.status === 200) {
            setCategoryProducts(response.data);
          }
        });

      setLoading(false);
    }

    fetchCategProducts();
  }, [category.id]);

  return (
    <>
      {loading ? (
        <>
          <div className="categoryContainer">
            <Row className="titleContainer">
              <Col sm={6} className="titleName">
                {category.name}
              </Col>
              <Col sm={6}></Col>
            </Row>
            <Row className="listPart">{placeholderProducts(7)}</Row>
          </div>
        </>
      ) : (
        <>
          {categoryProducts.length >= 1 ? (
            <div className="categoryContainer">
              <Row className="titleContainer">
                <Col sm={6} className="titleName">
                  {category.name}
                </Col>
                <Col sm={6} className="titleLink">
                  <Link to={`/${category.name}/`} preventScrollReset>
                    See All <ChevronRight size={24} />
                  </Link>
                </Col>
              </Row>
              <Row className="listPart">
                {categoryProducts.map((product, index) => {
                  return (
                    <ProductContainer
                      key={index}
                      product={product}
                      showBtn={true}
                    />
                  );
                })}
              </Row>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default CategoryContainer;
