import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Dash, Plus } from "react-bootstrap-icons";

const PMetaDataForm = ({ product, setProduct }) => {
  const [typedColor, setTypedColor] = useState("");

  const [typedMetaDataKey, setTypedMetaDataKey] = useState("");

  const [typedMetaDataValue, setTypedMetaDataValue] = useState("");

  function removeItem(index, slist) {
    slist.splice(index, 1);
    return slist;
  }

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Color </Form.Label>
        <div className="form-control-in-div">
          <InputGroup>
            <Form.Control
              type="search"
              placeholder="Add color..."
              className="me-2 no_validation_needed"
              aria-label="Search"
              aria-describedby="color-1"
              value={typedColor}
              onChange={(e) => setTypedColor(e.target.value)}
            />
            <InputGroup.Text id="color-1">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  if (typedColor && typedColor.length > 2) {
                    setProduct({
                      ...product,
                      meta_data: [
                        ...product.meta_data,
                        { key: "color", value: typedColor.toLowerCase() },
                      ],
                    });
                    setTypedColor("");
                  }
                }}
              >
                <Plus size={25} />
              </Button>
            </InputGroup.Text>
          </InputGroup>
        </div>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Meta Data </Form.Label>

        <div className="form-control-in-div">
          {product.meta_data &&
            product.meta_data.map((mt, index) => {
              return (
                <div key={index} className="two-inputs">
                  <div>
                    <Form.Group className="mb-3">
                      <Form.Label>Key </Form.Label>
                      <Form.Control
                        type="text"
                        value={mt.key}
                        className="no_validation_needed"
                        disabled
                      />
                    </Form.Group>
                  </div>
                  <div>
                    <Form.Group className="mb-3">
                      <Form.Label>Value </Form.Label>
                      <Form.Control
                        type="text"
                        value={mt.value}
                        className="no_validation_needed"
                        disabled
                      />
                    </Form.Group>
                  </div>
                  <Button
                    variant="outline-secondary"
                    key={index}
                    onClick={() => {
                      setProduct({
                        ...product,
                        meta_data: removeItem(index, product.meta_data),
                      });
                    }}
                  >
                    <Dash size={25} />
                  </Button>
                </div>
              );
            })}
          <div className="two-inputs">
            <div>
              <Form.Group className="mb-3">
                <Form.Label>Key </Form.Label>
                <Form.Control
                  type="text"
                  className="no_validation_needed"
                  value={typedMetaDataKey}
                  onChange={(e) => setTypedMetaDataKey(e.target.value)}
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group className="mb-3">
                <Form.Label>Value </Form.Label>
                <Form.Control
                  type="text"
                  className="no_validation_needed"
                  value={typedMetaDataValue}
                  onChange={(e) => setTypedMetaDataValue(e.target.value)}
                />
              </Form.Group>
            </div>
            <Button
              variant="outline-secondary"
              onClick={() => {
                if (
                  typedMetaDataKey.length > 2 &&
                  typedMetaDataValue.length > 2
                ) {
                  setProduct({
                    ...product,
                    meta_data: [
                      ...product.meta_data,
                      {
                        key: typedMetaDataKey.toLowerCase(),
                        value: typedMetaDataValue.toLowerCase(),
                      },
                    ],
                  });
                  setTypedMetaDataKey("");
                  setTypedMetaDataValue("");
                }
              }}
            >
              <Plus size={25} />
            </Button>
          </div>
        </div>
      </Form.Group>
    </>
  );
};

export default PMetaDataForm;
